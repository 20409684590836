export default {
  config: state => state.config,
  product_config: state => state.config.product,
  appLayout: state => state.config.appLayout,
  services_api: state => state.servicesApi,
  active_round: state => state.round.active,
  selected_round: state => state.round.selected,
  round_phase: state => state.round.phase,
  schedule: state => state.scheduleList.map(eventId => state.schedule[eventId]),
  active_bets: state => state.activeBets,
  disabled_betting: state => state.round.disabled,
  statistics: state => state.statistics,
  odds: state => state.round.selected.odds,
  notification: state => state.notification,
  notification_active: state => state.notification.active,
  translationss: state => state.translations,
  mobile: state => state.mobile,
  lang: state => state.language,
  loaded: state => state.loaded,
  theme: state => state.theme,
  currentRound: state => state.currentRound,
  expiredBets: state => state.expiredBets,
  currentScheduleRound: state => state.currentScheduleRound,
  nextRoundTime: state => state.nextRoundTime,
  isIsonis: state => state.isonisCountryList.indexOf(state.config.taxAuthority) > -1,
  userUuid: state => state.userUuid,
  initSetStateDate: state => state.initSetStateDate,
  isTerminal: state => state.isTerminal,
  terminalUserData: state => state.terminalUserData,
  ticketHistoryTerminal: state => state.ticketHistoryTerminal,
  ticketDetailsData: state => state.ticketDetailsData,
  betslipLockMessage: state => state.betslipLockMessage,
  inProgressRound: state => state.inProgressRound,
  isUserLoggedIn: state => state.isUserLoggedIn,
  isTerminalMouseCursorEnabled: state => state.terminalMouseCursorEnabled,
  activeTerminalLayout: state => state.activeTerminalLayout,
  allBettingDisabled: state => state.allBettingDisabled,
  jackpotState: state => state.jackpotState,
  isFreeBetMode: state => state.isFreeBetMode,
  isBoostActive: state => state.config.ui.config?.boost?.enabled || false,
  ajsDisabled: state => state.config?.ui?.config?.ajsDisabled ?? false,
  staticTheme: state => state.config.ui.config.theme ?? 'dark',
  isAuthChanged: state => state.authChanged,
};
